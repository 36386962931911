<template>
  <section class="section">
    <div class="container">
      <div :class="$style.header">
        <h2 :class="$style.title" data-aos="fade-up">
          Over 3,000 brand partners choose Sportscape Group
        </h2>
        <div :class="$style.logo">
          <img
            data-aos="fade-up"
            data-aos-delay="500"
            :src="require('@/assets/img/stats-sportscape-icon.png')"
          />
        </div>
      </div>

      <div :class="$style.benefits" id="benefits">
        <div
          v-for="(benefit, number) in benefits"
          :key="number"
          :class="$style.benefit"
          data-aos="fade-up"
          data-aos-anchor="#benefits"
          :data-aos-delay="500 + 100 * number"
        >
          <div :class="$style.number">{{ number + 1 }}</div>
          <h4 :class="$style.benefitTitle">{{ benefit.title }}</h4>
          <h6 :class="$style.benefitLabel">{{ benefit.label }}</h6>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionBenefits",
  data: () => ({
    benefits: [
      {
        title: "A huge, engaged audience",
        label: "24+ million members and 4m social fans",
      },
      {
        title: "A sport specialist partner for brands",
        label: "3000+ brand partners covering over 100 sports",
      },
      {
        title: "An effective complementary channel",
        label: "a signed-up audience for incremental sales",
      },
      {
        title: "Inspiring our engaged customers",
        label:
          "with great prices on great kit, quality content, and exciting adventures",
      },
      {
        title: "Backed by pan-European logistics",
        label: "and a data-driven tech platform",
      },
    ],
  }),
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: clamp(55px, 8vw, 160px);

  @include media-device(m) {
    flex-direction: column;
    text-align: center;
  }
}
.title {
  font-size: clamp(28px, 5vw, 75px);
  color: $teal;
  line-height: 110%;
  max-width: 60%;

  @include media-device(dl) {
    font-size: 75px;
  }
  @include media-device(t, m) {
    max-width: 100%;
  }
  @include media-device(m) {
    margin-bottom: 45px;
  }
}
.logo {
  max-width: 367px;
  img {
    width: 100%;
  }
  @include media-device(t) {
    max-width: 300px;
  }
  @include media-device(m) {
    max-width: 250px;
  }
}
.benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: clamp(65px, 4vw, 160px);
  grid-column-gap: 40px;

  @include media-device(t) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-device(m) {
    grid-template-columns: 1fr;
  }
}
.benefit {
  color: $purple;
}
.number {
  color: $teal;
  margin-bottom: 10px;
  font-size: clamp(30px, 4vw, 60px);
  font-weight: 600;
}
.benefitTitle {
  margin-bottom: clamp(12px, 2vw, 15px);
  font-size: clamp(20px, 2vw, 30px);
  font-weight: 600;
  line-height: 120%;
}
.benefitLabel {
  font-size: clamp(12px, 2vw, 18px);
  font-weight: 500;
}
</style>
