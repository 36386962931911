<template>
  <div class="wrapper">
    <section-welcome />
    <section-home />
    <section-statistics />
    <section-benefits />
    <section-slider />
    <section-contacts />
    <the-footer />
  </div>
</template>

<script>
import SectionWelcome from "@/components/SectionWelcome";
import SectionHome from "@/components/SectionHome";
import SectionStatistics from "@/components/SectionStatistics";
import SectionBenefits from "@/components/SectionBenefits";
import SectionContacts from "@/components/SectionContacts";
import TheFooter from "@/components/TheFooter";
import SectionSlider from "@/components/SectionSlider";

export default {
  name: "App",
  components: {
    TheFooter,
    SectionSlider,
    SectionContacts,
    SectionBenefits,
    SectionStatistics,
    SectionHome,
    SectionWelcome,
  },
};
</script>

<style lang="scss">
@import "@/assets/css/global.scss";

.wrapper {
  //overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}
</style>
