<template>
  <footer :class="$style.footer">
    <div class="container footer__container">
      <div :class="$style.icons">
        <a
          v-for="icon in icons"
          :key="icon"
          :href="icon.href"
          :class="$style.icon"
          target="_blank"
        >
          <font-awesome-icon :icon="`fa-brands ${icon.icon}`" />
        </a>
      </div>

      <img
        :src="require('@/assets/img/sportscape-white-primary-logo.svg')"
        :class="$style.logo"
        alt="sportscape-logo"
      />

      <div :class="$style.rights">© {{ currentYear }} Sportscape</div>
    </div>

    <img
      :src="require('@/assets/img/footer-sportscape-icon.png')"
      alt="sportscape-icon"
      :class="$style.bgItem"
    />
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data: () => ({
    icons: [
      {
        icon: "fa-square-facebook",
        href: "https://www.facebook.com/sportscapegroup",
      },
      {
        icon: "fa-instagram",
        href: "https://www.instagram.com/sportscape_group/",
      },
      {
        icon: "fa-linkedin-in",
        href: "https://www.linkedin.com/company/sportscape-group/",
      },
    ],
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}
</style>

<style lang="scss" module>
.footer {
  position: relative;
  overflow: hidden;
  background: #19203d;
  padding: 20px 0;
}
.icons {
  display: flex;
}
.icon {
  font-size: 20px;
  color: $white;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &:not(:last-child) {
    margin-right: 20px;
  }
}
.logo {
  width: 250px;
  margin-top: clamp(47px, 3vw, 65px);
  margin-bottom: 25px;

  @include media-device(m) {
    width: 180px;
    margin-bottom: 55px;
  }
}

.rights {
  color: $white;
  font-size: clamp(9px, 2vw, 12px);
}

.bgItem {
  position: absolute;
  z-index: 1;
  bottom: -70%;
  left: -3%;
  width: 265px;

  @include media-device(t) {
    left: -10%;
    bottom: -70%;
  }

  @include media-device(m) {
    width: 140px;
    left: -7%;
    bottom: -30%;
  }

  @include media-device(ms) {
    left: -13%;
    bottom: -35%;
  }
}
</style>
