import { createApp } from "vue";
import App from "./App.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import VueNumber from "vue-number-animation";
import { library as IconsLibrary } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faInstagram,
  faFacebookSquare,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const app = createApp(App);

IconsLibrary.add(faInstagram, faFacebookSquare, faLinkedinIn);

app.component("font-awesome-icon", FontAwesomeIcon);

app.use(
  AOS.init({
    duration: 1500,
    once: true,
    startEvent: "load",
  })
);

app.use(VueNumber);

app.mount("#app");
