<template>
  <header class="the-header">
    <div class="the-header__container">
      <img
        :src="require('@/assets/img/sportscape-white-primary-logo.svg')"
        class="the-header__logo"
      />
      <div class="the-header__list" :class="{ open: showMenu }">
        <a class="the-header__link" href="#contacts" @click="hideMenu">
          Contact us
        </a>
      </div>
      <MenuButton id="burger" @click="showMenu = !showMenu" :show="showMenu" />
    </div>
  </header>
</template>

<script>
import MenuButton from "@/components/TheMenuButton.vue";

export default {
  name: "TheHeader",
  components: {
    MenuButton,
  },
  data: () => ({
    showMenu: false,
  }),
  methods: {
    hideMenu() {
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.the-header {
  padding: 20px 0;
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__logo {
    display: block;
    z-index: 150;
    width: 250px;
    margin-left: -12px;

    @include media-device(m) {
      width: 200px;
    }
  }

  &__link {
    display: inline-block;
    position: relative;
    font-size: 18px;
    font-weight: 300;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: -2px;
      left: 0;
      background-color: #fff;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

#burger {
  display: none;
}

@media screen and (max-width: 576px) {
  .the-header__list {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    padding: 125px 0 0;
    background-color: #131933;
  }
  .the-header__link:first-child {
    font-weight: 400;
  }
  .the-header__list.open {
    display: flex;
    z-index: 151;
  }
  #burger {
    display: block;
  }
}
</style>
