<template>
  <div v-bind="$attrs" class="menu-btn" :class="{ open: show }">
    <span></span><span></span><span></span><span></span>
  </div>
</template>

<script>
export default {
  name: "MenuButton",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.menu-btn {
  z-index: 152;
  width: 27px;
  height: 70%;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.menu-btn span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.menu-btn span:nth-child(1) {
  top: -12px;
}

.menu-btn span:nth-child(2),
.menu-btn span:nth-child(3) {
  top: -1px;
}

.menu-btn span:nth-child(4) {
  top: 10px;
}

.menu-btn.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

.menu-btn.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-btn.open span:nth-child(4) {
  top: 6px;
  width: 0;
  left: 50%;
}
</style>
