<template>
  <section class="section-welcome">
    <div class="container">
      <div :class="$style.content">
        <the-header />
        <h1 :class="$style.title" data-aos="fade-up">
          <span>Europe’s <br />leading sports and outdoors hub</span>
          <img
            :src="require('@/assets/img/welcome-full-stop.svg')"
            :class="$style.logo"
            alt="sportscape mini logo"
          />
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
import TheHeader from "@/components/TheHeader";

export default {
  name: "SectionWelcome",
  components: {
    TheHeader,
  },
};
</script>

<style lang="scss" scoped>
.section-welcome {
  max-height: 770px;
  height: 100%;
  background-image: url("@/assets/img/welcome-bg.jpg");
  background-size: cover;

  @include media-device(t) {
    background-position: 70%;
  }

  @include media-device(m) {
    max-height: 600px;
    background-image: url("@/assets/img/welcome-bg-mobile.jpg");
    background-position: center;
  }
}
</style>

<style lang="scss" module>
.content {
  width: 100%;
  z-index: 100;
}
.title {
  max-width: 900px;
  margin-top: 170px;
  font-size: clamp(37px, 6vw, 93px);
  font-weight: 600;
  color: #fff;
  line-height: 125%;

  @include media-device(ds) {
    max-width: 700px;
  }

  @include media-device(t) {
    max-width: 600px;
  }

  @include media-device(m) {
    margin-top: 140px;
  }
}
.logo {
  width: 25px;
  height: 25px;
  margin-left: 6px;

  @include media-device(t) {
    width: 14px;
    height: 14px;
  }

  @include media-device(m) {
    width: 11px;
    height: 11px;
  }
}
</style>
