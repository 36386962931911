<template>
  <section class="section">
    <div class="container">
      <h2 :class="$style.title" data-aos="fade-up" data-aos-anchor="#contacts">
        Get in touch
      </h2>
      <div :class="$style.contacts" id="contacts">
        <a
          v-for="(contact, idx) in contacts"
          :key="contact.email"
          :class="$style.contact"
          :href="`mailto:${contact.href}`"
          data-aos="fade-up"
          data-aos-anchor="#contacts"
          :data-aos-delay="500 + 200 * idx"
        >
          <img
            :src="require('@/assets/img/contacts-email-icon.png')"
            :class="$style.icon"
          />
          <h3 :class="$style.contactTitle">{{ contact.title }}</h3>
          <h4 :class="$style.contactEmail">{{ contact.email }}</h4>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionContacts",
  data: () => ({
    contacts: [
      {
        title: "Working with Sportscape",
        email: "brands@sportscape.com",
        href: "rhys.jones@sportpursuit.co.uk,paul@privatesportshop.com",
      },
      {
        title: "Press enquiries",
        email: "press@sportscape.com",
        href: "adam.pikett@sportpursuit.co.uk,sebastien.r@privatesportshop.com",
      },
      {
        title: "Investor Relations",
        email: "investors@sportscape.com",
        href: "adam.pikett@sportpursuit.co.uk,sebastien.r@privatesportshop.com",
      },
    ],
  }),
};
</script>

<style lang="scss" module>
.title {
  @include media-device(m) {
    text-align: center;
  }
}
.contacts {
  display: flex;
  justify-content: space-between;
  margin-top: clamp(40px, 3vw, 70px);

  @include media-device(m) {
    flex-direction: column;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $dark;

  &:hover .icon {
    transform: translateY(-7px);
  }

  @include media-device(m) {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
.icon {
  width: 46px;
  transition: transform 0.5s ease-in-out;
}
.contactTitle {
  font-size: clamp(15px, 2vw, 24px);
  margin: clamp(14px, 2vw, 26px) 0;
}
.contactEmail {
  font-size: clamp(10px, 2vw, 15px);
  font-weight: 500;
}
</style>
