<template>
  <section class="section section-home">
    <div class="container">
      <div data-aos="fade-up" data-aos-anchor="#devices">
        <h3 :class="$style.subtitle">The home of</h3>
        <h2 :class="$style.title">
          SportPursuit & <br />
          Private Sport Shop
        </h2>
      </div>

      <div :class="$style.devices" id="devices">
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          :class="[$style.device, $style.sp]"
        >
          <img :src="require('@/assets/img/home-phone-2.png')" />
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="300"
          :class="[$style.device, $style.pssDesktop]"
        >
          <img :src="require('@/assets/img/home-desktop.png')" />
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="400"
          :class="[$style.device, $style.tablet]"
        >
          <img :src="require('@/assets/img/home-tablet.png')" />
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          :class="[$style.device, $style.pssMobile]"
        >
          <img :src="require('@/assets/img/home-phone-1.png')" />
        </div>
      </div>

      <div
        :class="$style.logos"
        data-aos="fade-up"
        data-aos-anchor="#devices"
        data-aos-delay="500"
      >
        <img :src="require('@/assets/img/home-logos.png')" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionHome",
};
</script>

<style lang="scss" scoped>
.section-home {
  color: $purple;
}
</style>

<style lang="scss" module>
.title {
  margin-bottom: clamp(75px, 6vw, 100px);
}
.subtitle {
  margin-bottom: 5px;
}
.devices {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 90px;
  width: 100%;

  @include media-device(t, m) {
    margin-bottom: 110px;
  }

  @include media-device(ms) {
    margin-bottom: 60px;
  }
}
.device {
  img {
    max-width: 100%;
  }
}
.logos {
  img {
    display: block;
    width: 50%;
    margin: 0 auto;

    @include media-device(t, m) {
      width: 70%;
    }

    @include media-device(m) {
      width: 100%;
    }
  }
}
.pssMobile {
  max-width: 107px;
}
.pssDesktop {
  max-width: 517px;

  @include media-device(m) {
    max-height: 120px;
  }
}
.tablet {
  max-width: 220px;
  margin-left: -80px;
  margin-bottom: -20px;

  @include media-device(m) {
    max-height: 130px;
    margin-left: -50px;
    margin-bottom: -30px;
  }

  @include media-device(ms) {
    max-height: 130px;
    margin-left: -30px;
    margin-bottom: -5%;
  }
}
.sp {
  max-width: 107px;
  margin-left: 20px;

  @include media-device(m) {
    margin-left: 5px;
  }
}

.pssMobile,
.sp {
  @include media-device(m) {
    max-height: 90px;
  }
}
</style>
