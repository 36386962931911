<template>
  <section class="slider__section">
    <div :class="$style.slider">
      <div v-for="index in 2" :class="$style.track" :key="index">
        <img
          v-for="slide in slides"
          :key="slide"
          :src="require(`@/assets/img/${slide}`)"
          :class="$style.img"
          alt="slide image"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionSlider",
  data: () => ({
    slides: [
      "slider-1.jpg",
      "slider-2.jpg",
      "slider-3.jpg",
      "slider-4.jpg",
      "slider-5.jpg",
      "slider-6.jpg",
      "slider-7.jpg",
      "slider-8.jpg",
      "slider-9.jpg",
      "slider-10.jpg",
    ],
  }),
};
</script>

<style lang="scss" module>
.slider {
  overflow: hidden;
  display: flex;

  &:hover {
    .track {
      animation-play-state: paused;
    }
  }
}

.track {
  display: flex;
  animation: scroll 35s linear infinite;

  @include media-device(m) {
    animation-duration: 15s;
  }
}

.img {
  transform: scale(0.95);
  height: 370px;

  @include media-device(m) {
    height: 200px;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
