<template>
  <section class="section statistics__section">
    <div class="container statistics__container">
      <h2 data-aos="fade-up" data-aos-anchor="#statistics">
        Building Europe’s <br />
        leading
        <span class="highlighted">
          sports <br />
          and outdoors hub
        </span>
      </h2>

      <div :class="$style.stats" id="statistics">
        <div
          v-for="(stat, idx) in stats"
          :key="stat.title"
          :class="[$style.stat, stat.highlighted && 'highlighted']"
          data-aos="fade-up"
          data-aos-anchor="#statistics"
          :data-aos-delay="400 + 100 * idx"
        >
          <div :class="$style.title">
            <Waypoint @change="onChange">
              <number
                :ref="`number${idx}`"
                :to="stat.to"
                :duration="2"
                animationPaused
                easing="Power1.easeOut"
              />{{ stat.m && "m" }} +
            </Waypoint>
          </div>
          <div :class="$style.subtitle">{{ stat.subtitle }}</div>
        </div>
      </div>
    </div>

    <img
      :src="require('@/assets/img/stats-sportscape-bg-icon-small.png')"
      :class="$style.bgItem"
    />
  </section>
</template>

<script>
import { Waypoint } from "vue-waypoint";

export default {
  name: "SectionStatistics",
  components: {
    Waypoint,
  },
  data: () => ({
    stats: [
      { to: 24, subtitle: "24+ million members", m: true },
      { to: 100, subtitle: "Covering over 100 sports" },
      {
        to: 3,
        subtitle: "3m social fans",
        highlighted: true,
        m: true,
      },
    ],
  }),
  methods: {
    onChange(waypointState) {
      if (waypointState.going === "OUT") return;

      for (let i = 0; i < 3; i++) {
        const ref = `number${i}`;
        this.$refs[ref][0].play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics__section {
  position: relative;
  color: $white;
  background: #19203d;
  overflow: hidden;
}
.statistics__container {
  z-index: 3;
  position: relative;
}
</style>

<style lang="scss" module>
.stats {
  display: flex;
  justify-content: space-between;
  margin: clamp(70px, 4vw, 150px) 0 0;

  @include media-device(m) {
    flex-direction: column;
  }
}
.stat {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-device(m) {
    &:not(:last-child) {
      margin-bottom: 65px;
    }
  }
}
.title {
  font-size: clamp(70px, 7vw, 100px);
  font-weight: bold;
}
.subtitle {
  font-size: clamp(14px, 1.5vw, 20px);
  font-weight: 600;
}
.bgItem {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -10%;
  max-width: 530px;

  @include media-device(t, m) {
    top: 0;
    max-width: 320px;
    right: -15%;
  }

  @include media-device(ms) {
    max-width: 280px;
    right: -25%;
  }
}
</style>
